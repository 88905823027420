@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@font-face {
  font-family: "accelerator";
  src: url(./fonts/accelerator.ttf);
  font-weight: normal;
}
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");
body {
  color: #ffffff !important;
  background-image: url("./Images/background.png");
  background-size: 100% 100%;
  background-repeat: "no-repeat";
  background-position: "center";
  height: 100%;
}

@media only screen and (max-width: 950px) {
  body {
    background-image: url("./Images/mobilebg.png"); /* Your mobile-specific background color */
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.cardBox {
  box-shadow: 0 2px 10px 0 #0006, 0 2px 25px 0 #00000080;
  display: grid;
  height: 50px;
  margin-bottom: 30px;
  place-items: center;
  position: relative;
  width: 176px;
  border-radius: 14px;
  overflow: hidden;
}

.cardBox:before {
  content: "";
  position: absolute;
  top: -3px;
  left: -53px;
  width: 160%;
  height: 50px;
  background: linear-gradient(271.68deg, #f0b90b 50%, #e8749e 50%);
  border-radius: 14px; /* Ensure it matches the card's border radius */
  animation: spin 3s linear infinite;
  z-index: 1;
}

.card-animation {
  align-items: center;
  background: linear-gradient(64.83deg, #30304e 15%, #27283c 60%);
  box-shadow: inset 0 30px 60px -12px #0006, inset 0 18px 36px -18px #00000080;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  padding: 20px;
  position: absolute;
  text-align: center;
  width: 170px;
  z-index: 2;
  border-radius: 14px;
}

.card-content {
  align-items: center;
  background: radial-gradient(circle at 10% 20%, #455666 0, #222 90%);
  display: flex;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  left: 50%;
  position: absolute;
  top: 50%;
  height: 45px;
  transform: translate(-50%, -50%);
  width: 100%;
  border-radius: 14px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
